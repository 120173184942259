import React, { useEffect, useState } from "react";
import "../Styles/DashboardStyle.css";
import {
  Table,
  Select,
  Spin,
  Button,
  Tooltip,
  Input,
  Flex,
  Modal,
  notification,
  Form,
} from "antd";
import { employeeTimesheet, report } from "../api";
import { employeeId } from "../utils/userDetails";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import BarChart from "./../utils/dashboardGraph";
import {
  SearchOutlined,
  CheckCircleFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import { render } from "@testing-library/react";
import { RxCross1 } from "react-icons/rx";
import { IoCheckmarkOutline } from "react-icons/io5";
import { set } from "@ant-design/plots/es/core/utils";

const { Option } = Select;

const Dashboard = () => {
  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthIndex = new Date().getMonth();
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardGraph, setDashboardGraph] = useState([]);
  const currentDate = moment();
  const [currentMonth, setCurrentMonth] = useState(currentDate.month() + 1);
  const [currentYear, setCurrentYear] = useState(currentDate.year());
  const [availableMonths, setAvailableMonths] = useState(
    monthArray.slice(0, currentMonthIndex + 1)
  );
  const [projectLoading, setProjectLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [api, contextHolder] = notification.useNotification();
  const [tableData, setTableData] = useState([]);
  const [clickedMails, setClickedMails] = useState(() => {
    return JSON.parse(localStorage.getItem("clickedMails")) || [];
  });
  const [projectType, setProjectType] = useState("");

  useEffect(() => {
    localStorage.setItem("clickedMails", JSON.stringify(clickedMails));
  }, [clickedMails]);

  const openNotification = (message, icon) => {
    api.open({
      message: message,
      duration: 2,
      icon: icon,
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    fetchDashboardProjectsData();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ backgroundColor: "#ffc069", padding: "0 5px" }}>
          {text}
        </span>
      ) : (
        text
      ),
  });

  useEffect(() => {
    const fetchData = async () => {
      await fetchDashboardData();
    };
    fetchData();
  }, [currentMonth, currentYear]);

  const fetchDashboardProjectsData = async () => {
    try {
      setProjectLoading(true);
      const response = await employeeTimesheet.approverDashboard({
        employeeNo: employeeId,
        year: currentYear,
        month: currentMonth,
      });
      setDashboardData(
        response.projects.map((project) => ({
          ...project,
          draftEmployees: project.draft || [],
          pendingApprovalEmployees: project.pendingApproval || [],
          rejectedEmployees: project.rejected || [],
          approvedEmployees: project.approved || [],
        }))
      );
      const table = response.projects.map((project, index) => ({
          key: index + 1,
          projectId: project.projectId,
          projectName: project.projectName,
          projectManager: project.deliveryManagerName || "N/A",
          isInternal: project.isInternal,
          projectManagerEmail: project.deliveryManagerEmail || "",
          employeeEmails: project.employeeEmails || [],
          draftCount: project.status.draft || 0,
          pendingApprovalCount: project.status.pendingApproval || 0,
          rejectedCount: project.status.rejected || 0,
          approvedCount: project.status.approved || 0,
          draftEmployees: project.draft || [],
          pendingApprovalEmployees: project.pendingApproval || [],
          rejectedEmployees: project.rejected || [],
          approvedEmployees: project.approved || [],
        }));
        if (projectType === "All") {
          setTableData(table);
        } else if (projectType === "Internal") {
          const modifyTableData = table.filter(
            (project) => project.isInternal == true
          );
          setTableData(modifyTableData);
        } else if (projectType === "Client") {
          const modifyTableData = table.filter(
            (project) => project.isInternal == false
          );
          setTableData(modifyTableData);
        } else{
          setTableData(table);
        }
      setProjectLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setProjectLoading(false);
    }
  };

  const fetchDashboardGraphData = async () => {
    try {
      setGraphLoading(true);
      const response = await employeeTimesheet.approverDashboardGraph({
        employeeNo: employeeId,
        year: currentYear,
        month: currentMonth,
      });
      setDashboardGraph(response.employeeTotalWorkedHours);
      setGraphLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setGraphLoading(false);
    }
  };

  const fetchDashboardData = async () => {
    try {
      await Promise.all([
        fetchDashboardProjectsData(),
        fetchDashboardGraphData(),
      ]);
    } catch (error) {
      console.error("Error in fetching dashboard data:", error);
    }
  };

  const handleMonthChange = (value) => {
    setCurrentMonth(value);
  };

  const handleYearChange = (value) => {
    setCurrentYear(value);

    if (value < currentDate.year()) {
      setAvailableMonths(monthArray);
      setCurrentMonth(currentMonthIndex);
    } else if (value === currentDate.year()) {
      setAvailableMonths(monthArray.slice(0, currentMonthIndex + 1));
      setCurrentMonth(currentMonthIndex+1);
    } else {
      setAvailableMonths([]);
    }
  };

  const handleProjectClick = (projectId) => {
    navigate("/timesheet-approval", { state: { projectId } });
  };

  const handleMailToEmployee = async (employeeEmails) => {
    try {
      const payload = {
        to_emails: [
          ...employeeEmails?.pendingApprovalEmployees.map(
            (employee) => employee.email
          ),
          ...employeeEmails?.rejectedEmployees.map(
            (employee) => employee.email
          ),
        ],
        name: [
          ...employeeEmails?.pendingApprovalEmployees.map(
            (employee) => employee.name
          ),
          ...employeeEmails?.rejectedEmployees.map((employee) => employee.name),
        ],
        projectName: employeeEmails?.projectName,
        projectManager: employeeEmails?.projectManager,
      };
      const response = await employeeTimesheet.mailSendToEmployees(payload);
      // setClickedMails([...clickedMails, { project: employeeEmails.projectId, type: "employees" }]);
      const currentYear = new Date().getFullYear();
    const currentMonth = new Date().toLocaleString("default", { month: "short" }); // e.g., "Feb"

    setClickedMails([
      ...clickedMails,
      { project: employeeEmails.projectId, type: "employees", year: currentYear, month: currentMonth }
    ]);
      openNotification(
        response.message,
        <CheckCircleFilled style={{ color: "#52c41a" }} />
      );
      setIsModalVisible(false);
    } catch (error) {
      console.log("error", error);
      setIsModalVisible(false);
    }
  };

  const handleModalEmployeeMail = (employeeEmails) => {
    setModalContent({
      title: "Mail to Employees",
      description: `Do you want to send an email to the selected employees?`,
      onConfirm: () => {
        handleMailToEmployee(employeeEmails);
      },
    });
    setIsModalVisible(true);
  };

  const handleMailToManager = async (projectManagerEmail) => {
    const payload = {
      to_emails: projectManagerEmail?.projectManagerEmail,
      name: [
        ...projectManagerEmail?.draftEmployees.map((employee) => employee.name),
      ],
      projectName: projectManagerEmail?.projectName,
      projectManager: projectManagerEmail?.projectManager,
    };
    const response = await employeeTimesheet.mailSendToManager(payload);
    // setClickedMails([...clickedMails, { project: projectManagerEmail.projectId, type: "manager" }]);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().toLocaleString("default", { month: "short" });

    setClickedMails([
      ...clickedMails,
      { project: projectManagerEmail.projectId, type: "manager", year: currentYear, month: currentMonth }
    ]);
    openNotification(
      response.message,
      <CheckCircleFilled style={{ color: "#52c41a" }} />
    );
    setIsModalVisible(false);
  };

  const handleModelManagerMail = (projectManagerEmail) => {
    setModalContent({
      title: "Mail to Manager",
      description: `Do you want to send an email to the project manager?`,
      onConfirm: () => {
        handleMailToManager(projectManagerEmail);
      },
    });
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const renderTooltipContent = (employees) => {
    return employees && employees.length > 0
      ? employees.map((employee) => (
          <div key={employee?.employeeNo}>{employee?.name}</div>
        ))
      : "No employees";
  };

  const handleDownloadExcel = async (newProject) => {
    try {
      const res = await report.generateReport({
        projectId: newProject.projectId,
        month: currentMonth,
        year: currentYear,
      });

      if (res.msg === "Reports generated successfully") {
        const resp = await report.getReport({
          projectId: newProject.projectId,
          month: currentMonth,
          year: currentYear,
        });

        const base64String = resp.file;
        const project = newProject?.projectName;
        const projectName = project
          ? project.replace(/\s+/g, "_")
          : "Unknown_Project";
        const month = monthArray[currentMonth-1];
        const filename = `${projectName}_Timesheet_${month}_${currentYear}.xlsx`;

        // Create an element and set its attributes
        const link = document.createElement("a");
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
        link.download = filename;
        link.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isClicked = (projectId, type) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().toLocaleString("default", { month: "short" });
  
    return clickedMails.some(
      (mail) => mail.project === projectId && mail.type === type && mail.year === currentYear && mail.month === currentMonth
    );
  };
  

  const columns = [
    {
      title: "ID",
      dataIndex: "key",
      key: "key",
      width: 40,
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      width: 200,
      ...getColumnSearchProps("projectName"),
      render: (text, record) => (
        <span
          style={{ cursor: "pointer", color: "#1677ff" }}
          onClick={() => handleProjectClick(record.projectId)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Client Project",
      dataIndex: "isInternal",
      key: "isInternal",
      width: "10%",
      render: (text, record) => {
        return text ? (
          <RxCross1
            style={{ margin: "0 0 0 1rem", color: "#df0b2e", fontSize: "16px" }}
          />
        ) : (
          <IoCheckmarkOutline
            style={{ margin: "0 0 0 1rem", color: "green", fontSize: "18px" }}
          />
        );
      },
    },
    {
      title: "Project Manager",
      dataIndex: "projectManager",
      key: "projectManager",
      width: 180,
      ...getColumnSearchProps("projectManager"),
    },
    {
      title: "Draft",
      dataIndex: "pendingApprovalCount",
      key: "pendingApprovalCount",
      width: 90,
      render: (count, record) => (
        <Tooltip title={renderTooltipContent(record?.pendingApprovalEmployees)}>
          <span style={{ cursor: "pointer" }}>{count}</span>
        </Tooltip>
      ),
    },
    {
      title: "Pending Approval",
      dataIndex: "draftCount",
      key: "draftCount",
      width: 150,
      render: (count, record) => (
        <Tooltip title={renderTooltipContent(record?.draftEmployees)}>
          <span style={{ cursor: "pointer" }}>{count}</span>
        </Tooltip>
      ),
    },
    {
      title: "Rejected",
      dataIndex: "rejectedCount",
      key: "rejectedCount",
      width: 90,
      render: (count, record) => (
        <Tooltip title={renderTooltipContent(record?.rejectedEmployees)}>
          <span style={{ cursor: "pointer" }}>{count}</span>
        </Tooltip>
      ),
    },
    {
      title: "Approved",
      dataIndex: "approvedCount",
      key: "approvedCount",
      width: 90,
      render: (count, record) => (
        <Tooltip title={renderTooltipContent(record?.approvedEmployees)}>
          <span style={{ cursor: "pointer" }}>{count}</span>
        </Tooltip>
      ),
    },
    {
      title: "Mail to Manager",
      key: "mailToManager",
      width: 130,
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handleModelManagerMail(record)}
          disabled={record.draftCount == 0}
          style={{ color: isClicked(record.projectId, "manager") ? "#008000" : "blue", fontWeight: 400 }}
        >
          Mail Manager
        </Button>
      ),
    },
    {
      title: "Mail to Employees",
      key: "mailToEmployee",
      width: 140,
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handleModalEmployeeMail(record)}
          disabled={record.pendingApprovalCount + record.rejectedCount == 0}
          style={{ color: isClicked(record.projectId, "employees") ? "#008000" : "blue", fontWeight: 400 }}
        >
          Mail Employees
        </Button>
      ),
    },
    {
      title: "Report",
      key: "download_timesheet",
      width: 60,
      render: (_, record) => (
        <Button type="link" onClick={() => handleDownloadExcel(record)}>
          <DownloadOutlined style={{ fontSize: "14px" }} />
        </Button>
      ),
    },
  ];

  const filterProjects = (type) => {
    const referenceStructure = dashboardData.map((project, index) => ({
      key: index + 1,
      projectId: project.projectId,
      projectName: project.projectName,
      projectManager: project.deliveryManagerName || "N/A",
      isInternal: project.isInternal,
      projectManagerEmail: project.deliveryManagerEmail || "",
      employeeEmails: project.employeeEmails || [],
      draftCount: project.status.draft || 0,
      pendingApprovalCount: project.status.pendingApproval || 0,
      rejectedCount: project.status.rejected || 0,
      approvedCount: project.status.approved || 0,
      draftEmployees: project.draft || [],
      pendingApprovalEmployees: project.pendingApproval || [],
      rejectedEmployees: project.rejected || [],
      approvedEmployees: project.approved || [],
    }));
    if (type === "All") {
      setProjectType("All");
      setTableData(referenceStructure);
      return referenceStructure;
    } else if (type === "Internal") {
      setProjectType("Internal");
      const modifyTableData = referenceStructure.filter(
        (project) => project.isInternal == true
      );
      setTableData(modifyTableData);
    } else if (type === "Client") {
      setProjectType("Client");
      const modifyTableData = referenceStructure.filter(
        (project) => project.isInternal == false
      );
      setTableData(modifyTableData);
    }
    return;
  };

  return (
    <>
      <div
        style={{
          background: "#ffffff",
          minHeight: "100vh",
        }}
      >
        <div className="tile">
          <div
            className="filter-bar"
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "20px",
              justifyContent: "space-between",
            }}
          >
            <span className="tileHeading">Dashboard</span>
            <Flex justify="space-between">
              <span style={{ display: "flex", gap: "10px" }}>
                <div>
                  <div className="selectDropDown">Project Type</div>
                  <Select
                    placeholder="Select Type"
                    defaultValue="All"
                    onChange={(value) => filterProjects(value)}
                    size="large"
                    style={{ width: "110px" }}
                  >
                    <Select.Option value="All">All</Select.Option>
                    <Select.Option value="Internal">Internal</Select.Option>
                    <Select.Option value="Client">Client</Select.Option>
                  </Select>
                </div>
                <div>
                  <div className="selectDropDown">Year</div>
                  <Select
                    placeholder="Select Year"
                    defaultValue={currentYear}
                    onChange={handleYearChange}
                    size="large"
                    style={{ width: "100px" }}
                  >
                    {Array.from(
                      { length: 5 },
                      (_, i) => currentDate.year() - 4 + i
                    ).map((year) => (
                      <Select.Option key={year} value={year}>
                        {year}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div>
                  <div className="selectDropDown">Month</div>
                  <Select
                    placeholder="Select Month"
                    value={currentMonth}
                    onChange={handleMonthChange}
                    size="large"
                    style={{ width: "120px" }}
                  >
                    {availableMonths.map((month, index) => (
                      <Select.Option key={index} value={index + 1}>
                        {month}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </span>
            </Flex>
          </div>

          {projectLoading ? (
            <div
              style={{
                textAlign: "center",
                height: "450px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <div>
              <Table
                columns={columns}
                dataSource={tableData}
                pagination={{ pageSize: 10 }}
                bordered
              />
            </div>
          )}
        </div>

        {graphLoading ? (
          <div
            style={{
              textAlign: "center",
              height: "450px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div style={{ marginTop: "20px" }} className="tile">
            <BarChart dashboardGraph={dashboardGraph} />
          </div>
        )}
      </div>

      {contextHolder}

      <Modal
        title={modalContent.title}
        visible={isModalVisible}
        onOk={modalContent.onConfirm}
        onCancel={handleModalCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>{modalContent.description}</p>
      </Modal>
    </>
  );
};

export default Dashboard;
