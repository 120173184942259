import { Flex, Modal, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoCheckmarkOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { Project, adminProjectView, deliveryManager } from "../api";
import EmployeeViewForm from "../components/Form/EmployeeViewForm";
import TableComponent from "../utils/TableComponent";
import { convertTime } from "../utils/dateFormat";
import { employeeId, isDeliveryManager, timesheetAdmin } from "../utils/userDetails";


const generateCalendarData = (year, month) => {
  const startOfMonth = moment().year(year).month(month-1).startOf("month");
  const endOfMonth = moment(startOfMonth).endOf("month");

  let currentDate = startOfMonth.clone();
  const days = [];

  while (currentDate <= endOfMonth) {
    days.push({
      date: currentDate.date(),
      day: currentDate.format("dddd").slice(0, 3).toUpperCase(),
      dayOfWeek: currentDate.day(),
      isWeekend: currentDate.day() === 0 || currentDate.day() === 6,
    });
    currentDate.add(1, "day");
  }
  return days;
};

const ProjectViewTimeSheet = () => {
  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthIndex = new Date().getMonth();
  const currentDate = moment();
  const [currentMonth, setCurrentMonth] = useState(currentDate.month() + 1);
  const [currentYear, setCurrentYear] = useState(currentDate.year());
  const [availableMonths, setAvailableMonths] = useState(
      monthArray.slice(0, currentMonthIndex + 1)
    );
  const [project, setProject] = useState(null);
  const [projectArray, setProjectArray] = useState([]);
  const [projectTypeArray, setProjectTypeArray] = useState([
    { label: "All", value: "all" },
    { label: "Internal", value: "isInternal" },
    { label: "Client", value: "isClient" },
  ]);
  const [projectType, setProjectType] = useState("all");
  const [projectData, setProjectData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [expandedWeeks, setExpandedWeeks] = useState({ 0: true });

  const handleMonthChange = (value) => {
    setCurrentMonth(value);
  };

  const handleYearChange = (value) => {
    setCurrentYear(value);

    if (value < currentDate.year()) {
      setAvailableMonths(monthArray);
      setCurrentMonth(currentMonthIndex);
    } else if (value === currentDate.year()) {
      setAvailableMonths(monthArray.slice(0, currentMonthIndex + 1));
      setCurrentMonth(currentMonthIndex+1);
    } else {
      setAvailableMonths([]);
    }
  };

  const handleProjectChange = (value) => {
    setProject(value);
  };

  const handleProjectTypeChange = (value) => {
    setProjectType(value);
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      total: projectData.length,
    });
  };

  const createWeeks = (calendarData) => {
    const weeks = [];
    let currentWeek = [];
    const startDay = calendarData[0].dayOfWeek;

    for (let i = 0; i < startDay; i++) {
      currentWeek.push(null);
    }
    calendarData.forEach((item, index) => {
      currentWeek.push(item);
      if (item.dayOfWeek === 6 || index === calendarData.length - 1) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    });

    while (currentWeek.length > 0 && currentWeek.length < 7) {
      currentWeek.push(null);
    }
    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }
    if (weeks.length === 6 && !weeks[5].some((day) => day !== null)) {
      weeks.pop();
    }
    return weeks;
  };

  const calendarData = generateCalendarData(currentYear, currentMonth);
  const weeks = createWeeks(calendarData);

  const toggleWeek = (weekIndex) => {
    setExpandedWeeks((prevState) => ({
      ...prevState,
      [weekIndex]: !prevState[weekIndex],
    }));
  };


  const columns = [
    {
      title: "Employee",
      dataIndex: "employeeName",
      key: "employeeName",
      fixed: "left",
      change: true,
      width: 160,
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      fixed: "left",
      change: true,
      width: 180,
    },
    {
      title: "Project Manager",
      dataIndex: "deliveryManagerName",
      key: "deliveryManagerName",
      fixed: "left",
      change: true,
      width: 160,
    },
    {
      title: "Billable",
      dataIndex: "isBillable",
      key: "isBillable",
      fixed: "left",
      width: 80,
      render: (text, record) => {
      
        return !text ? (
          <RxCross1
            style={{ margin: "0 0 0 1rem", color: "df0b2e", fontSize: "16px" }}
          />
        ) : (
          <IoCheckmarkOutline
            style={{ margin: "0 0 0 1rem", color: "green", fontSize: "18px" }}
          />
        );
      },
    },
    ...weeks.map((week, weekIndex) => {
      const isExpanded = expandedWeeks[weekIndex];
      return {
        title: (
          <div
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => toggleWeek(weekIndex)}
          >
            Week {weekIndex + 1} {isExpanded ? "↑" : "↓"}
          </div>
        ),
        width: 100,
        children: isExpanded
          ? week
              .filter((item) => item !== null)
              .map((item, dayIndex) => {
                return {
                  title: (
                    <div style={{ textAlign: "center" }}>
                      <div>{item.day}</div>
                      <div style={{ fontSize: "14px" }}>
                        {item.date} {monthArray[currentMonth-1].slice(0, 3)}
                      </div>
                    </div>
                  ),
                  dataIndex: `day${item.date}`,
                  key: item.date,
                  width: 80,
                  change: false,
                  render: (text, record) => {
                    if (!record.logs[item.date - 1]) return null; // Handle cases where no logs are present

                    const minutesWorked = record.logs[item.date - 1]?.minutes;
                    const description = record.logs[item.date - 1];
                    const employeeName = record.employeeName;
                    const isOnLeave = record.logs[item.date - 1]?.isOnLeave;
                    const leaveType = record.logs[item.date - 1]?.leaveType;
                    const Date = moment({
                      year: currentYear,
                      month: currentMonth,
                      date: item.date,
                    }).format("DD-MM-YYYY");
                    return {
                      children: (
                        <div
                          onClick={() => {
                            if (!item.isWeekend) {
                              setSelectedDay({
                                ...item,
                                month: monthArray[currentMonth].slice(0, 3),
                                // hoursWorked,
                                minutesWorked,
                                description: description,
                                employeeName,
                                Date,
                              });
                              setIsModalVisible(true);
                            }
                          }}
                          style={{
                            cursor: item.isWeekend ? "default" : "pointer",
                            height: 55,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color:
                              minutesWorked / 60 >= 8
                                ? "#06c974"
                                : leaveType === 1 || leaveType === 3
                                ? "#df0b2e"
                                : "#000000",
                          }}
                        >
                          {isOnLeave
                            ? leaveType === 1
                              ? "Leave"
                              : leaveType === 3
                              ? "Holiday"
                              : convertTime(minutesWorked)
                            : convertTime(minutesWorked)}
                        </div>
                      ),
                      props: {
                        style: {
                          background: item.isWeekend ? "pink" : "white",
                          padding: 0,
                        },
                      },
                    };
                  },
                };
              })
          : [],
      };
    }),
    {
      title: "Total Time",
      key: "totalHours",
      fixed: "right",
      align: "center",
      width: 100,
      render: (text, record) => {
        const totalMinutes = record.logs.reduce(
          (acc, curr) => acc + curr.minutes,
          0
        );
        return <b>{convertTime(totalMinutes)}</b>;
      },
    },
  ];

  const getAllProjects = async () => {
    try {
      const response = await Project.getAllProjects({
        employeeNo: employeeId,
        admin: timesheetAdmin,
        deliveryManager: isDeliveryManager,
        projectType: projectType,
      });
      const newResponse = [
        {
          label: "All", 
          value: "all", 
        },
        ...response.projects.map((project) => ({
          ...project,
          isInternal: false,
          deliveryManager: project.deliveryManagerName || "N/A",
        })),
        ...response.internalProjects.map((project) => ({
          ...project,
          isInternal: true,
          deliveryManager: project.deliveryManagerName || "N/A",
        })),
      ];
      setProjectArray(
        newResponse.map((response) => {
         
          return {
            label: response.projectName || response.label, 
            value: response.projectId || response.value,
            deliveryManager: response.deliveryManager || "N/A",
          };
        })
      );
      setProject(newResponse[0].value);
    } catch (error) {
      console.log(" error", error);
    }
  };

  const projectView = async () => {
    try {
      setLoading(true);
      const response = await adminProjectView.getProjectView({
        employeeNo: employeeId,
        admin: timesheetAdmin,
        projectType: projectType,
        projectId: project,
        month: currentMonth,
        year: currentYear,
      });
     
     // setProjectData(response.employees);
     setProjectData(
      response.employees.map((employee) => ({
        ...employee,
        deliveryManager: projectArray.find(
          (proj) => proj.label === employee.projectName
        )?.deliveryManager || "N/A",
      }))
    );
    } catch (error) {
      console.log("project view error", error);
    } finally {
      setLoading(false);
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    getAllProjects();
  }, [projectType]);

  useEffect(() => {
    if (project && (currentMonth || currentMonth === 0) && currentYear) {
      setProjectData([]);
      projectView();
    }
  }, [currentMonth, project, currentYear, projectType]);


  return (
    <div
      style={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Flex justify="space-between" align="center" gap="2rem">
        <div style={{ display: "flex", gap: "1rem" }}>
          <div>
            <div className="selectDropDown">Year</div>
            <Select
              placeholder="Select Year"
              defaultValue={currentYear}
              onChange={handleYearChange}
              size="large"
              style={{ width: "100px", textAlign: "left" }}
            >
              {Array.from(
                { length: 5 },
                (_, i) => currentDate.year() - 4 + i
              ).map((year) => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <div className="selectDropDown">Month</div>
            <Select
              placeholder="Select Month"
              value={currentMonth}
              onChange={handleMonthChange}
              size="large"
              style={{ width: "120px", textAlign: "left" }}
            >
              {availableMonths.map((month, index) => (
                <Select.Option key={index} value={index + 1}>
                  {month}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <div className="selectDropDown">Project Type</div>
            <Select
              placeholder="Select Project Type"
              value={projectType}
              onChange={handleProjectTypeChange}
              size="large"
              style={{ width: "120px", textAlign: "left" }}
              options={projectTypeArray}
              filterOption={filterOption}
              optionFilterProp="children"
              showSearch
            />
          </div>
          <div>
            <div className="selectDropDown">Project</div>
            <Select
              placeholder="Select Project"
              value={project}
              onChange={handleProjectChange}
              size="large"
              style={{ width: "250px", textAlign: "left" }}
              options={projectArray}
              filterOption={filterOption}
              optionFilterProp="children"
              showSearch
            />
          </div>
        </div>
      </Flex>

      <TableComponent
        loading={loading}
        rows={projectData}
        columns={columns}
        scrollx={1400}
        scrolly={300}
        pagination={pagination}
        onChange={handleTableChange}
        summary={true}
        isActivity={true}
        locale={{ emptyText: "No data available for given input" }}
        weeks={weeks}
      />

      <Modal
        title={
          <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
            {`Task Details ${selectedDay?.Date}`}
          </div>
        }
        visible={isModalVisible}
        footer={null}
        width={550}
        onCancel={() => setIsModalVisible(false)}
      >
        <EmployeeViewForm hide={setIsModalVisible} selectedDay={selectedDay} />
      </Modal>
    </div>
  );
};

export default ProjectViewTimeSheet;
